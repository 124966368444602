import React from 'react'
import styles from './Footer.module.css'

const d = new Date;
const year = d.getFullYear();

function Footer(props) {
  const { ...restProps } = props
  return (
    <footer className={styles.footer}>
      <ul className={styles.footerLinks}>
        <li className="Email">
          <a href="mailto:engineering@deepdiveagency.com" target="_blank" rel="noopener">
            Email
          </a>
        </li>
      </ul>
      <div className={styles.footerBrew}>© DeepDive Agency LLC {year}</div>
    </footer>
  )
}

export default Footer
