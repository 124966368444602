import React from 'react'
import styles from './Header.module.css'
import { Link } from 'gatsby'
import peaceImg from './peace.png'

class Header extends React.Component {
  render() {
    return (
      <header>
        <div className={styles.header}>
          <Link to="/" className={styles.headerLogo}>
            <img
              src={peaceImg}
              alt="DeepDive Agency"
              className={styles.headerLogoImage}
            />
            <span>DeepDive Agency</span>
          </Link>
          <nav>
            <ul className={styles.headerLinks}>
              <li className={styles.headerLink}>
                <Link activeClassName={styles.active} to="/">
                  Portfolio
                </Link>
              </li>
              <li className={styles.headerLink}>
                <Link activeClassName={styles.active} to="/about/">
                  About
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
