// Please update this file as per your requirement. 
// You must update fullstoryID and googleAnalyticsID as per your requirement. 

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: "DeepDive Agency's Portfolio", // Navigation and Site Title
  siteTitleAlt: "DeepDive Agency's Portfolio - A Front-End Developer / UX Designer who designs and develops pixel perfect user interface, experiences and web applications.",
  siteDescription: "DeepDive Agency's Portfolio - A Front-End Developer / UX Designer who designs and develops pixel perfect user interface, experiences and web applications.",
  siteUrl: 'https://deepdiveagency.com', // Domain of your site. No trailing slash!
  siteName: 'DeepDive Agency', // Name of Developer
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: './src/Assets/favicon.png', // Used for SEO and manifest
  // siteImage: '', // Used for SEO and manifest

  // Manifest and Progress color
  manifestShortName: "DeepDive Agency's Portfolio",
  manifestBackgroundColor: '#fafafa',
  manifestThemeColor: '#79e9d2',
  manifestDisplay: 'standalone',

  // Social Meta data
  ogSiteName: "DeepDive Agency's Portfolio", // Facebook Site Name
  ogLanguage: 'en_EN',
  googleAnalyticsID: '',

  //  FullStory id 
  fullstoryID: '',
   

  toolsList : [
    'React',
    'Angularjs',
    'Javascript es6',
    'SCSS',
    'CSS',
    'HTML',
    'PHP',
    'GIT',
    'SVG',
    'Figma',
    'Sketch',
    'Photoshop',
    'Jest',
    'PostCSS',
    'GraphQL',
    'Illustrator',
    'Abstract',
    'Zeplin',
    'Nodejs',
    'Gulp',
    'Webpack',
    'Wordpress',
    'Drupal',
    'Gatsby',
    'Saas',
    'a11y',
    'Performance',
  ],
  skillsList:[
    'JAMStack development',
    'Agile Development',
    'Front-end/UI Architecture',
    'UI/UX prototyping',
    'Website Performance',
    'Rebranding',
    'Design tooling',
    'Responsive Web Design',
    'Design System',
    'Test Driven Development',
    'A/B Testing',
    'Technical Documentation',
    'Website Personalization',
    'SEO strategy',
    'cross-browser testing',
    'Website accessibility',
    'Data Visualization',
    'Google Analytics/Optimize',
  ],
  Recommendations: [
    { 
      name:'Someone',
      role: 'Software Engineer at Pixel and Binary',
      time: 'July 7, 2017',
      recco: 'I had the benefit of meeting DeepDive Agency while working together at Sony and have continued to stay in touch as a friend and colleague. ',
    }
  ]
}
